@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-balham.css";

.avatar {
    border-radius: 50%;
}

.contactAvatar {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    border: 2px solid rgb(162, 138, 138);
}

.light-drop-shadow {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, .075) !important;
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.shadow {
    box-shadow: 0 .25rem 1rem rgba(0, 0, 0, .15) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

/*Google input style*/

.group {
    position: relative;
    margin-bottom: 45px;
}

input {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 300px;
    border: none;
    border-bottom: 1px solid #757575;
    background-color: transparent;
}

.textInputShort {
    width: 150px !important;
}

/* input:focus {
    outline: none;
} */

/* label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
} */

/* input:focus~label, input:valid~label {
    top: -20px;
    font-size: 14px;
    color: #5264AE;
} */

.bar {
    position: relative;
    display: block;
    width: 300px;
}

.barShort {
    width: 150px !important;
}

.bar:before, .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #5264AE;
    transition: 0.2s ease all;
}

.bar:before {
    left: 50%;
}

.bar:after {
    right: 50%;
}

input:focus~.bar:before, input:focus~.bar:after {
    width: 50%;
}

.highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}

input:focus~.highlight {
    animation: inputHighlighter 0.3s ease;
}

html, body {
    height: 100%;
    /* background-color: #fdfdfd !important; */
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1000000 !important;
}

@media only screen and (max-width: 768px) {
    .largeModal .modal-dialog {
        max-width: 100% !important;
    }

    .regularModal .modal-dialog {
        max-width: 100% !important;
    }
}

@media only screen and (min-width: 768px) {
    .largeModal .modal-dialog {
        max-width: 80% !important;
    }

    .regularModal .modal-dialog {
        max-width: 60% !important;
    }
}

@media print {
    .doNotPrint {
        display: none !important;
    }

    /* Override igniteui-angular@13.2.24 css to fix print Form J converted to scaled and blur image */
    * {
        filter: none !important;
    }
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-content {
    pointer-events: all;
    color: black;
    font-weight: bold;
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-bar {
    pointer-events: all;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout, .mat-radio-label-content {
    pointer-events: all;
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-label {
    position: relative !important;
}

.popover {
    max-width: 100vw !important;
    max-height: 50vh;
    overflow: auto;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* ============================================================================
 *                                Opacity
 * ============================================================================*/

.opacity-90 {
    opacity: 0.9;
}
.opacity-80 {
    opacity: 0.8;
}
.opacity-70 {
    opacity: 0.7;
}
.opacity-60 {
    opacity: 0.6;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-0 {
    opacity: 0;
}

/* ============================================================================
 *                           Customize Bootstrap
 * ============================================================================*/

.table-custom { --bs-table-bg: transparent !important; }

tr[class^="text-"] th,
tr[class^="text-"] td {
  color: inherit;
}

/* ============================================================================
 *                           Material Components
 * ============================================================================*/

 .mat-slide-toggle-lg {
    --mdc-form-field-label-text-weight: 700 !important;
    --mdc-form-field-label-text-size: 18px !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    --mat-standard-button-toggle-height: 2 !important;
}

/* ============================================================================
 *                                 Others
 * ============================================================================*/

.top-snackbar {
    margin-top: 100px !important;
}

.btn:focus, .btn:focus:active {
    box-shadow: none !important;
}

.pointer:not(.disabled):hover {
    cursor: pointer;
}

button.disabled {
    cursor: unset !important;
    pointer-events: all !important;
}